import Foto from "../../../pictures/314472729_778188146614466_2569173996259762878_n.jpg";
import { TypeAnimation } from "react-type-animation";
import ReactFloaterJs from "react-floaterjs";
import Fade from "react-reveal/Fade";

const kopTitel = () => {
  return (
    <TypeAnimation
      sequence={[
        "Student Applied Computer Science",
        2000,
        "Mobile/Web & Enterprise Developer",
        2000,
        "Tech Enthusiast",
        2000,
        "Eager to learn",
        2000,
      ]}
      speed={15}
      className="text-black text-2xl"
      repeat={Infinity}
    />
  );
};

export const Header = () => {
  return (
    // <div className="header">
    //   <div className="mx-auto max-w-7xl px-6 md:flex md:space-x-6 xl:px-0">
    //     <div className="md:mt-20 md:w-1/2 flex flex-col justify-center items-center text-center">
    //       <div className="text-3xl md:text-4xl lg:text-5xl text-black">
    //         Hello World <span className="wave text-3xl md:text-4xl lg:text-5xl">👋</span>
    //       </div>
    //       <div className="text-3xl md:text-4xl lg:text-5xl text-black mt-2">I am Anasio Claeys</div>

    //       <div className="mt-6">{kopTitel()}</div>
    //     </div>
    //     <div className="mt-20 md:w-1/2 flex justify-center">
    //       <img className="rounded-full max-w-sm h-auto" src={Foto} alt="Foto van Anasio Claeys" />
    //     </div>
    //   </div>
    // </div>

    <div className="header">
      <div className="mx-auto max-w-7xl px-6 md:flex md:space-x-6 xl:px-0 md:mt-44" name="home">
        <Fade left>
          <div className="md:mt-20 md:w-1/2 flex flex-col justify-center items-center text-center mt-8">
            <div className="text-3xl md:text-4xl lg:text-5xl text-black">
              Hello World <span className="wave text-3xl md:text-4xl lg:text-5xl">👋</span>
            </div>
            <div className="text-3xl md:text-4xl lg:text-5xl text-black mt-2">
              I am Anasio Claeys
            </div>

            <div className="mt-6">{kopTitel()}</div>
          </div>
        </Fade>
        <div className="mt-10 md:w-1/2 flex justify-center">
          <Fade right>
            <ReactFloaterJs>
              <img
                className="rounded-full max-w-sm lg:max-w-[400px] w-full h-auto"
                src={Foto}
                alt="Foto van Anasio Claeys"
              />
            </ReactFloaterJs>
          </Fade>
        </div>
      </div>
    </div>
  );
};
