import { AboutMe } from "./aboutme/AboutMe";
import { Contact } from "./contact/Contact";
import { Header } from "./header/Header";
import { Projects } from "./projects/Projects";
import { Skills } from "./skills/Skills";

export const Home = () => {
  return (
    <div className="home">
      <div className="header">
        <Header />
      </div>

      <AboutMe />
      <Skills />
      {/* <Projects /> */}
      <Contact />
    </div>
  );
};
