import { AiFillHtml5 } from "react-icons/ai";
import { FaCss3Alt, FaReact } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io";
import { BsGit } from "react-icons/bs";
import { BiLogoJava, BiLogoSpringBoot } from "react-icons/bi";
import { SiCsharp, SiMysql } from "react-icons/si";
import { BiLogoPython } from "react-icons/bi";

const Card = ({ icon, text }) => {
  return (
    <div className="bg-white rounded-lg p-8 lg:m-3 shadow-md text-center">
      {/* Hier voegen we een flex-container toe om het pictogram en de tekst te centreren */}
      <div className="flex flex-col items-center">
        {/* Voeg de className 'text-5xl' toe aan het pictogram om het groter te maken */}
        <div className="mb-2 text-5xl">{icon}</div>
        {/* Hier kun je de tekststijlen aanpassen, bijvoorbeeld 'text-xl font-bold' */}
        <div className="text-xl font-bold">{text}</div>
      </div>
    </div>
  );
};

export const Skills = () => {
  return (
    // <div className="header mx-auto max-w-7xl px-6 md:space-x-6 xl:px-0 mt-40">
    //   <div className="">
    //     <div className="text-4xl text-center">Development Skills</div>
    //     <div className="flex justify-center">
    //       {/* Hier gebruiken we een flex-container die de kaarten omhult */}
    //       <div className="flex flex-wrap justify-center">
    //         {/* Vijf kolommen voor kaarten */}
    //         <div className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/5 p-4">
    //           <Card icon={<AiFillHtml5 />} text="HTML5" />
    //         </div>
    //         <div className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/5 p-4">
    //           <Card icon={<FaCss3Alt />} text="CSS3" />
    //         </div>
    //         <div className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/5 p-4">
    //           <Card icon={<IoLogoJavascript />} text="JAVASCRIPT" />
    //         </div>
    //         <div className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/5 p-4">
    //           <Card icon={<FaReact />} text="REACT" />
    //         </div>
    //         <div className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/5 xl:w-1/5 p-4">
    //           <Card icon={<BsGit />} text="GIT" />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="">
    //     <div className="flex justify-center">
    //       {/* Hier gebruiken we een flex-container die de kaarten omhult */}
    //       <div className="flex flex-wrap justify-center">
    //         {/* Drie kolommen voor kaarten */}
    //         <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-4">
    //           <Card icon={<AiFillHtml5 />} text="HTML5" />
    //         </div>
    //         <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-4">
    //           <Card icon={<FaCss3Alt />} text="CSS3" />
    //         </div>
    //         <div className="w-full sm:w-1/2 md:w-full lg:w-1/3 xl:w-1/3 p-4 flex justify-center">
    //           <Card icon={<IoLogoJavascript />} text="JAVASCRIPT" />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>

    // <div className="header mx-auto max-w-7xl px-6 md:space-x-6 xl:px-0 mt-40">
    //   <div className="text-4xl text-center">Development Skills</div>
    //   <div className="flex justify-center">
    //     {/* Hier gebruiken we een flex-container die de kaarten omhult */}
    //     <div className="flex flex-wrap justify-center">
    //       {/* Eerste rij: vier kolommen voor kaarten */}
    //       <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4">
    //         <Card icon={<AiFillHtml5 />} text="HTML5" />
    //       </div>
    //       <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4">
    //         <Card icon={<FaCss3Alt />} text="CSS3" />
    //       </div>
    //       <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4">
    //         <Card icon={<IoLogoJavascript />} text="JAVASCRIPT" />
    //       </div>
    //       <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4">
    //         <Card icon={<FaReact />} text="REACT" />
    //       </div>
    //       {/* Tweede rij: drie kolommen voor kaarten met dezelfde grootte als de eerste rij */}
    //       <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4">
    //         <Card icon={<AiFillHtml5 />} text="HTML5" />
    //       </div>
    //       <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4">
    //         <Card icon={<FaCss3Alt />} text="CSS3" />
    //       </div>
    //       <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4">
    //         <Card icon={<IoLogoJavascript />} text="JAVASCRIPT" />
    //       </div>
    //     </div>
    //   </div>
    // </div>

    //     <div className="header mx-auto max-w-7xl px-6 md:space-x-6 xl:px-0 mt-40">
    //   <div className="text-4xl text-center">Development Skills</div>
    //   <div className="flex justify-center">
    //     {/* Hier gebruiken we een flex-container die de kaarten omhult */}
    //     <div className="flex flex-wrap justify-center">
    //       {/* Eerste rij: vier kolommen voor kaarten */}
    //       <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4">
    //         <Card icon={<AiFillHtml5 />} text="HTML5" />
    //       </div>
    //       <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4">
    //         <Card icon={<FaCss3Alt />} text="CSS3" />
    //       </div>
    //       <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4">
    //         <Card icon={<IoLogoJavascript />} text="JAVASCRIPT" />
    //       </div>
    //       <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4">
    //         <Card icon={<FaReact />} text="REACT" />
    //       </div>
    //       {/* Tweede rij: drie kolommen voor kaarten met dezelfde grootte als de eerste rij */}
    //       <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4">
    //         <Card icon={<AiFillHtml5 />} text="HTML5" />
    //       </div>
    //       <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4">
    //         <Card icon={<FaCss3Alt />} text="CSS3" />
    //       </div>
    //       <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4">
    //         <Card icon={<IoLogoJavascript />} text="JAVASCRIPT" />
    //       </div>
    //       {/* Derde rij: twee kolommen voor kaarten met dezelfde grootte als de tweede rij */}
    //       <div className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-4">
    //         <Card icon={<AiFillHtml5 />} text="HTML5" />
    //       </div>
    //       <div className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-4">
    //         <Card icon={<FaCss3Alt />} text="CSS3" />
    //       </div>
    //     </div>
    //   </div>
    // </div>

    <div className="header mx-auto max-w-7xl px-6 md:space-x-6 xl:px-0 md:mt-40 mt-20">
      <div className="text-4xl text-center mb-4" name="skills">
        Development Skills
      </div>
      <div className="flex justify-center">
        {/* Hier gebruiken we een flex-container die de kaarten omhult */}
        <div className="flex flex-wrap justify-center">
          {/* Eerste rij: vier kolommen voor kaarten */}
          <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4 hover:scale-110">
            <Card icon={<AiFillHtml5 />} text="HTML5" />
          </div>
          <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4 hover:scale-110">
            <Card icon={<FaCss3Alt />} text="CSS3" />
          </div>
          <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4 hover:scale-110">
            <Card icon={<IoLogoJavascript />} text="JAVASCRIPT" />
          </div>
          <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4 hover:scale-110">
            <Card icon={<FaReact />} text="REACT" />
          </div>
          {/* Tweede rij: drie kolommen voor kaarten met dezelfde grootte als de eerste rij */}
          <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4 hover:scale-110">
            <Card icon={<BiLogoJava />} text="JAVA" />
          </div>
          <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4 hover:scale-110">
            <Card icon={<SiCsharp />} text="C#" />
          </div>
          <div className="w-1/2 sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 p-4 hover:scale-110">
            <Card icon={<BiLogoSpringBoot />} text="SPRING" />
          </div>
          {/* Derde rij: twee kolommen voor kaarten met dezelfde grootte als de tweede rij */}
          <div className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-4 hover:scale-110">
            <Card icon={<BiLogoPython />} text="PYTHON" />
          </div>
          <div className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3 p-4 hover:scale-110">
            <Card icon={<SiMysql />} text="MySQL" />
          </div>
        </div>
      </div>
    </div>
  );
};
