import * as Scroll from "react-scroll";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

export const Navbar = () => {
  return (
    <nav className="">
      <div className="flex flex-wrap items-center justify-center mx-auto p-4">
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span class="sr-only">Open main menu</span>
          <svg
            class="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg  md:flex-row md:space-x-20 md:mt-0 md:border-0 dark:border-gray-700 items-center">
            <li>
              <Link
                className="block py-2 pl-3 pr-4 text-gray-900 rounded md:p-0 text-xl"
                to="home"
                spy={true}
                smooth={true}
                duration={500}
                offset={-80}
                id="navLink"
                href="/"
              >
                {/* <a
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded md:p-0 text-xl"
                  href="/"
                  id="navLink"
                  name="home"
                > */}
                {/* <a
                  href="#"
                  className="block py-2 pl-3 pr-4 text-white text-gray-900 rounded  md:text-white md:p-0 text-xl"
                  aria-current="page"
                  id="navLink"
                > */}
                HOME
                {/* </a> */}
                {/* </a> */}
              </Link>
            </li>
            <li>
              {/* <a
                href="#"
                className="block py-2 pl-3 pr-4 text-gray-900 rounded text-white md:border-0 md:p-0 dark:text-white text-xl"
                id="navLink"
              > */}
              <Link
                to="aboutme"
                spy={true}
                smooth={true}
                duration={500}
                offset={-80}
                className="block py-2 pl-3 pr-4 text-gray-900 rounded md:p-0 text-xl"
                id="navLink"
                href="/"
              >
                {/* <a
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded md:p-0 text-xl"
                  href="/aboutme"
                  id="navLink"
                > */}
                ABOUT ME
                {/* </a> */}
              </Link>

              {/* </a> */}
            </li>
            <li>
              <Link
                to="skills"
                spy={true}
                smooth={true}
                duration={500}
                offset={-60}
                className="block py-2 pl-3 pr-4 text-gray-900 rounded md:p-0 text-xl"
                id="navLink"
                href="/"
              >
                {/* <a
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded md:p-0 text-xl"
                  href="/skills"
                  id="navLink"
                > */}
                SKILLS
                {/* </a> */}
              </Link>
            </li>
            <li>
              <Link
                to="contact"
                spy={true}
                smooth={true}
                duration={500}
                className="block py-2 pl-3 pr-4 text-gray-900 rounded md:p-0 text-xl"
                id="navLink"
                href="/"
              >
                {/* <a
                  className="block py-2 pl-3 pr-4 text-gray-900 rounded md:p-0 text-xl"
                  href="/contact"
                  id="navLink"
                > */}
                CONTACT
                {/* </a> */}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
