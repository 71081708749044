import Foto from "../../../pictures/COBIE LORENZO-662.jpg";
import CV from "../../../data/CV_AnasioClaeys.pdf";
import ReactFloaterJs from "react-floaterjs";

export const AboutMe = () => {
  return (
    // <div className="mx-auto max-w-7xl px-6 md:space-x-6 xl:px-0 lg:mt-96 sm:mt-24">
    //   <div className="ml-16">
    //     <div className="text-3xl ">About Me</div>
    //     <div className="mt-5 text-lg">
    //       <img
    //         className="rounded-full max-w-sm lg:max-w-[300px] w-full h-auto"
    //         src={Foto}
    //         alt="Foto van Anasio Claeys"
    //       />
    // <p>
    //   Hello, I'm Anasio Claeys, a 20-year-old student pursuing a Bachelor's degree in Applied
    //   Computer Science at HoGent. Currently in my third year, I specialize in Mobile &
    //   Enterprise Development. My passion for learning drives me to explore new software
    //   development tools and programming languages. I thrive on challenges and am eager to
    //   expand my knowledge in the ever-evolving world of technology. Welcome to my e-portfolio,
    //   where you can discover my journey in the world of software development.
    // </p>
    //     </div>
    //     <div>
    //       {/* <button className="border">Download my CV</button> */}
    //       <button
    //         type="button"
    //         className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
    //       >
    //         Download my CV
    //       </button>
    //     </div>
    //   </div>
    // </div>

    <div className="header md:mt-40 lg:mt-80">
      <div className="mx-auto max-w-7xl px-6 md:flex md:space-x-6 xl:px-0">
        <div className="md:w-1/2 flex flex-col justify-center items-center  mt-8 md:mt-0">
          <ReactFloaterJs>
            <img
              className="hidden md:block rounded-full max-w-sm lg:max-w-[400px] w-full h-auto"
              src={Foto}
              alt="Foto van Anasio Claeys"
            />
          </ReactFloaterJs>
        </div>
        <div className="md:w-1/2 flex justify-center mt-16">
          {/* About me met daaronder die tekst */}
          <div className="text-left">
            <div className="text-4xl text-center md:text-left" name="aboutme">
              About Me
            </div>
            <div className="mt-5 text-lg mb-5">
              <p>
                Hello, I'm Anasio Claeys, a 20-year-old student pursuing a Bachelor's degree in
                Applied Computer Science at HoGent. Currently in my third year, I specialize in
                Mobile & Enterprise Development. My passion for learning drives me to explore new
                software development tools and programming languages. I thrive on challenges and am
                eager to expand my knowledge in the ever-evolving world of technology. Welcome to my
                e-portfolio, where you can discover my journey in the world of software development.
              </p>
            </div>
            <div className="text-center md:text-left">
              {/* <button className="border">Download my CV</button> */}
              <a href={CV} download={CV}>
                <button className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
                  Download my CV
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
