import React, { useRef } from "react";
import emailjs, { send } from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";

export const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_8exzkea", "template_63ix56i", form.current, "6FkVZE3w3TrMwt6Jf").then(
      (result) => {
        console.log(result.text);
        console.log("Email sent successfully!");
        toast.success("Email sent successfully!", {
          // plaats rechts onderaan
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
        });
        // clear the form
        form.current.reset();
      },
      (error) => {
        console.log(error.text);
      }
    );
  };
  return (
    <div className="header mx-auto max-w-7xl px-6  xl:px-0 md:mt-40 mt-20 mb-8">
      <h1 className="text-4xl mb-6 text-center mb-4" name="contact">
        Contact Me
      </h1>
      <div className="text-center mb-8">
        <p className="text-lg">
          If you have any questions or want to work together, please feel free to contact me 👋
        </p>
      </div>

      <form ref={form} onSubmit={sendEmail} className="flex flex-wrap">
        <div className="w-full md:w-1/2 md:mb-0 md:pr-5">
          <div className="mb-3">
            <label htmlFor="name" className="block mb-2 text-lg">
              Your Name*
            </label>
            <input
              type="name"
              id="name"
              className="border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
              required
              name="name"
            />
          </div>

          <div className="mb-3">
            <label htmlFor="subject" className="block mb-2 text-lg">
              Subject*
            </label>
            <input
              type="subject"
              id="subject"
              className="border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
              required
              name="subject"
            />
          </div>

          <div className="mb-3">
            <label htmlFor="subject" className="block mb-2 text-lg">
              Email*
            </label>
            <input
              type="email"
              id="email"
              className="border text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
              required
              name="user_email"
            />
          </div>
        </div>

        <div className="w-full md:w-1/2 md:pl-2">
          <div className="mb-6">
            <label htmlFor="message" className="block mb-2 text-lg">
              Your message*
            </label>
            <textarea
              id="message"
              rows="10"
              className="block p-2.5 w-full text-sm rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Leave a comment..."
              required
              name="message"
            ></textarea>
          </div>
        </div>

        <div className="mb-6">
          {/* <button
            type="submit"
            className="w-full px-3 py-4 text-white bg-blue-500 rounded-md focus:bg-blue-600 focus:outline-none"
            onClick={sendEmail}
          >
            Send Message
          </button> */}

          <button
            type="submit"
            className="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700"
          >
            Send Message
          </button>
        </div>
      </form>

      {/* <form ref={form} onSubmit={sendEmail}>
        <label>Name</label>
        <input type="text" name="name" />
        <label>Email</label>
        <input type="email" name="user_email" />
        <label>Message</label>
        <textarea name="message" />
        <input type="submit" value="Send" />
      </form> */}
    </div>
  );
};
